
import React from 'react';
import imageLoader from './Images';


class Offer extends React.Component{
    state = {
      noticiasitem: []
    }

    componentDidMount(){
      const noticiasitem = imageLoader()
      this.setState({noticiasitem})
    }

    render() {
      return (
        <section className="section-top-98 section-bottom-124">
          <div className="shell">
            <h1>Contenidos recientes</h1>
            
            <div className="range offset-top-66 range-xs-center text-left">
            {this.state.noticiasitem.map(({id,image,title,description,href}) => <div className="cell-xs-10 cell-sm-6 cell-md-3" key ={id}><a className="thumbnail-classic" href={href} target="_self">
                <figure><img width="370" height="278" src={image} alt=""/>
                </figure></a>
      <div>
            <h6 className="offset-top-24">{title}</h6>
      </div>
            <p className="text-dark offset-top-4 text-extra-small">{description}</p>
    </div>
                               
              
            )}
            </div>
          </div>
        </section>
         );
        }  
      }
    
    export default Offer;