import React from 'react';
import ContactForm from '../components/contactform'
import Breadcrumb from '../components/Breadcrumbs';


class Contact extends React.Component {
    render(){
        return (
            <div>
               <Breadcrumb name="Contáctanos" icon="icon-lg mdi mdi-map-marker-circle icon icon-white"/>
               <ContactForm/> 
               
            </div>
            
        )
    }
}
  export default Contact;