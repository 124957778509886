import React from 'react';
import Slider from '../components/Slider';
import Offer from '../components/Offer';

class Maincontent extends React.Component {
    render(){
        return (
            <div>
                <Slider/>
                <Offer/>
            </div>
            
        )
    }
}
  export default Maincontent;