import React from 'react';
/*components import*/
import Menu from './components/Menu';
import Footer from './components/Footer'
/*pages import*/
import Maincontent from './pages/Main';
import AboutUs from './pages/About-us';
import Portafolio from './pages/Portafolio';
import Proyectos from './pages/Proyectos'; 
import Legal from './pages/informacion-legal';
import Datos from './pages/autorizacion-datos';
import Contact from './pages/contactus';
import Sitemap from './pages/Sitemap'


import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


class App extends React.Component{
  
  render() {
    return (
      <Router>
      <div>
        <Menu/>
        <Switch>
          <Route path="/" exact component={Maincontent} />
          <Route path="/about" component={AboutUs} />
          <Route path="/portafolio" component={Portafolio} />
          <Route path="/proyectos" component={Proyectos} />
          <Route path="/legal" component={Legal} />
          <Route path="/datos" component={Datos} />
          <Route path="/contact" component={Contact} />
          <Route path="/sitemap" component={Sitemap} />
          
        </Switch>
        <Footer/>
      </div>
      </Router>
     );
  }
}
export default App;
