import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import menu from '../default/sliders.json';
import '../css/bodystyle.css'

class Slider extends React.Component{
    state = {
        menu: menu
      }
  
      render() {
        return (
            <React.Fragment>
            <Carousel>
            {this.state.menu.map(({id,image,title,description,href}) =>
                <Carousel.Item key={id}>
                <img
                className="d-block w-100"
                src={image}
                alt="Bienvenidos a Ideas y desarrollos tecnológicos"
                />
                <Carousel.Caption className="infoBlock shell d-none d-md-block">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </Carousel.Caption>
            </Carousel.Item>
            )}
            </Carousel>
            </React.Fragment>
            );
    }  
  }
  export default Slider;