import React from 'react';
/*components import*/
import Breadcrumb from '../components/Breadcrumbs';
import Textimageblock from '../components/Textimageblock';
import Whychooseus from '../components/Whychooseus';
import Accordion from '../components/ClassicAccordion';
import Lightparallaxcontentbox from '../components/Lightparallaxcontentbox';
/*styles import*/
import mision from '../default/mision.json';

class AboutUs extends React.Component {
    state = {
        mision: mision
      }
    render(){
        return (
            <div>
                <Breadcrumb name="Quiénes Somos" icon="icon-lg mdi mdi-account-multiple icon icon-white"/>
                <main className="page-content">
                <Textimageblock image="images/about.jpg" title="Quiénes Somos" parrafo="Ideas y Desarrollos tecnológicos S.A.S Es una compañía líder en investigación y desarrollo, implementación, integración, administración y soporte de innovaciones tecnológicas de hardware y software para sistemas microcontrolados orientados a telemetría, monitoreo y localización."/>
                <Lightparallaxcontentbox/>
                <Whychooseus/>
                <section className="section-50">
                <div class="shell">
                    <br/>
                    <h1>Misión y Visión</h1>
                    {this.state.mision.map(
                    e => 
                    <Accordion title={e.title} key ={e.id}>
                        {e.description}
                    </Accordion>
                    )
                    }
                </div> 
                </section>
                </main>
            </div>
            
        )
    }
}
  export default AboutUs;