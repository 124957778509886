import React from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

class ContactForm extends React.Component {
    constructor(){
        super()
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            message: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.changeCaptcha = this.changeCaptcha.bind(this)
        this.expiredCaptcha = this.expiredCaptcha.bind(this)
    }

    changeCaptcha = e =>{
        this.setState({captchstatus: true})
    }

    expiredCaptcha = e =>{
        this.setState({captchstatus: false})
    }

    handleChange = e =>{
        this.setState({[e.target.name]: e.target.value})
    }

    async handleSubmit (e) {
        
        const recaptchaValue = recaptchaRef.current.getValue();
        e.preventDefault()
        if(this.state.captchstatus){
            const{firstname, lastname, email, message} = this.state;
            alert('Gracias por comunicarte con nosotros!');
            this.setState({firstname: "", lastname: "", email: "", message: ""});  
            const form = await axios.post('/api/form',{
                firstname,
                lastname,
                email,
                message  
              })            
              
        }else{
            alert('Por favor verifica que eres humano!');
        }
        
    }

    render(){
        return (
        <section className="section-98 section-sm-top-110 section-sm-bottom-124">
            <div className="shell">
                <div className="range range-xs-center">
                <div className="cell-xs-10 cell-sm-8 cell-md-8 text-md-left">
                    <h1>Contáctanos</h1>
                    <hr className="divider divider-lg bg-mantis reveal-inline-block"/>
                    <div className="offset-top-30 offset-md-top-66">
                    <p>Nos puedes contactar usando los siguientes datos de contacto. te responderemos en horario laboral, email o teléfono o puedes usar nuestro formato de contacto para dejar cualquier inquietud sobre nuestros productos y servicios. </p>
                    <br/>
                    </div>
                    <div className="offset-top-50">
                    
                    <form onSubmit={this.handleSubmit} id="contact-form" method="POST" className="rd-mailform text-left">
                        <div className="range range-xs-center">
                        <div className="cell-sm-6">
                            <div className="form-group">
                            <label for="git-3-mailform-first-name" className="form-label form-label-outside form-label-size-lg form-label-size-lg">Nombre</label>
                            <input id="git-3-mailform-first-name" type="text" name="firstname" value={this.state.firstname} data-constraints="@Required" className="form-control" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="cell-sm-6">
                            <div className="form-group">
                            <label for="git-3-mailform-last-name" className="form-label form-label-outside form-label-size-lg">Apellido</label>
                            <input id="git-3-mailform-last-name" type="text" name="lastname" value={this.state.lastname} data-constraints="@Required" className="form-control" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="cell-sm-12">
                            <div className="form-group offset-sm-top-20">
                            <label for="git-3-mailform-email" className="form-label form-label-outside form-label-size-lg">E-mail</label>
                            <input id="git-3-mailform-email" type="email" name="email" value={this.state.email} data-constraints="@Required @Email" className="form-control" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="cell-sm-12">
                            <div className="form-group offset-sm-top-20">
                            <label for="git-3-mailform-message" className="form-label form-label-outside form-label-size-lg">Mensaje</label>
                            <textarea id="git-3-mailform-message" name="message" value={this.state.message} data-constraints="@Required" className="form-control" onChange={this.handleChange}></textarea>
                            </div>
                        </div>
                        <div className="cell-sm-12 offset-top-30 text-center text-md-left">
                            <button type="submit" style={{width:"166px"}} className="btn btn-primary">Enviar</button>
                        </div>
                        <div className="cell-sm-12">
                            <br/>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Lcr7UkUAAAAAERFqPjifvVGINU1qCghZn3nrbur"
                            onChange={this.changeCaptcha}
                            onExpired={this.expiredCaptcha}
                        />
                        <br/>
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
                <div className="cell-xs-10 cell-md-4 offset-top-66 offset-md-top-0">
                    
                    <aside className="text-left">
                    <div className="inset-md-left-50 inset-lg-left-74">
                        <div>
                        <h5 className="text-darker">Redes sociales</h5>
                        </div>
                        <div className="text-subline offset-top-10"></div>
                        <ul className="list-inline list-inline-sm list-inline-white offset-top-30 text-darker">
                        <li className="h6 offset-top-0"><span className="big"><a href="#" className="text-dark fa fa-twitter"></a></span></li>
                        <li className="h6 offset-top-0"><span className="big"><a href="#" className="text-dark fa fa-linkedin"></a></span></li>
                        
                        </ul>
                        <div className="offset-top-41">
                        <h5 className="text-darker">Teléfono</h5>
                        </div>
                        <div className="text-subline offset-top-10"></div>
                        <div className="p unit unit-spacing-xs unit-horizontal offset-top-24">
                        <div className="unit-left"><span className="icon icon-xxs mdi mdi-cellphone-android text-primary"></span></div>
                        <div className="unit-body">57 1 4929165 - 57 1 4043013 </div>
                        </div>
                        <div className="offset-top-41">
                        <h5 className="text-darker">Dirección</h5>
                        </div>
                        <div className="text-subline offset-top-10"></div>
                        <div className="p unit unit-spacing-xs unit-horizontal offset-top-24">
                        <div className="unit-left"><span className="icon icon-xxs mdi mdi-map-marker text-primary"></span></div>
                        <div className="unit-body">
                            <address>Cra 7 No. 156-68 Edificio North Point Torre III Piso piso 10 oficina 1006 / Bogotá - Colombia</address>
                        </div>
                        </div>
                        <div className="offset-top-41">
                        <h5 className="text-darker">Horario de atención</h5>
                        </div>
                        <div className="text-subline offset-top-10"></div>
                        <div className="p unit unit-spacing-xs unit-horizontal offset-top-24">
                        <div className="unit-left"><span className="icon icon-xxs mdi mdi-clock text-primary"></span></div>
                        <div className="unit-body">
                            <address>Lunes–Viernes: 7:00am–5:00pm</address>
                        </div>
                        </div>
                        
                    </div>
                    </aside>
                </div>
                </div>
            </div>
        </section>
    )}}
export default ContactForm;