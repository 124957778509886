import React from 'react';

const titleStyle = {
  fontWeight: 600,
  fontSize: '30px',
  color: 'white'
}

class Breadcrumb extends React.Component {
    render(){
        return (
<section className="breadcrumb-classic">
        <div className="shell section-34 section-sm-50">
          <div className="range range-lg-middle">
            <div className="cell-lg-2 veil reveal-sm-block cell-lg-push-2"><span className={this.props.icon}></span></div>
            <div className="cell-lg-5 veil reveal-lg-block cell-lg-push-1 text-lg-left">
              <h2 style={titleStyle}><span className="big">{this.props.name}</span></h2>
            </div>
            <div className="offset-top-0 offset-sm-top-10 cell-lg-5 offset-lg-top-0 small cell-lg-push-3 text-lg-right">
              <ul className="list-inline list-inline-dashed p">
                <li><a href="/">Inicio</a></li>
                <li>{this.props.name}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <svg className="svg-triangle-bottom" xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            
          </defs>
        </svg>
      </section>)
    }
}
  export default Breadcrumb;