import React from 'react';

class Columm extends React.Component {
  state = {
    e: this.props.e
  }
    render(){
        return (
        <div><br/>
                    <h3>{this.state.e.title} </h3>
                        <hr/>
                        {
                        (typeof(this.state.e.subsecciones)=='object')?
                        <div>
                          {
                            this.state.e.subsecciones.map(
                              f =>
                              <div><br/> 
                                
                            {typeof(f.subtitulo!=='undefined')?
                            <div>
                              {
                                <h6>{f.subtitulo}</h6>
                              } 
                              
                            </div>
                            :null
                            } 
                            {
                                (typeof(f.componentes)=='object')?
                                <div>
                                <ul class="list list-unstyled">
                                  {
                                    f.componentes.map(
                                     g =>
                                    <li class="post-meta inset-left-20"><span class={g.icon}></span> <span class="text-middle"><a href="intense-photographer-portfolio">{g.nombreComponente}</a></span>
                                        </li>

                                    )            
                                  }
                                 </ul>
                                </div>
                                :null
                            }                               
                                
                                
                              </div>
                              
                            )
                          }
                        </div>
                        :<br/>
                        } 
                        
        </div>)
    }
}export default Columm;


















