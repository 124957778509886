import React from 'react';
import Breadcrumb from '../components/Breadcrumbs';
import Rowmap from '../components/rowmap';



class Sitemap extends React.Component {
    
    render(){
        return (
            <div>
                <Breadcrumb name="Sitemap" icon="mdi mdi-sitemap icon icon-white"/>
                <Rowmap/>
                
                
            </div>
            
        )
    }
}
  export default Sitemap;