import React from 'react';


class LightParallaxBox extends React.Component {
    render(){
        return (
        <section>
          <div>
            <div >
              <div class="bg-overlay-white">
                <div class="shell section-50">
                  <h1 style={{'text-align': 'center'}}>Historia</h1>
                  <hr class="divider bg-mantis"/>
                  <div class="range range-xs-center text-sm-left">
                    <div class="cell-sm-9 cell-lg-12">
                      <div class="range range-xs-center">
                        <div class="cell-lg-5">
                          <p>Ideas y Desarrollos tecnológicos comienza sus operaciones en el año de 2020, como respuesta a la necesidad de atender los requerimientos y solicitudes tecnológicas de sus clientes. Se conformó un equipo de ingenieros y técnicos en sistemas y electrónica de alto nivel, para estar a la vanguardia de los permanentes avances tecnológicos que el mercado requería.</p>
                        </div>
                        <div class="cell-lg-5">
                          <p>Hoy en día hemos llevado esa experiencia mucho más allá, ampliando nuestro conocimiento personal y de la empresa, llevando a cabo importantes avances en el desarrollo de redes de sensores inalámbricos, nueva generación de dispositivos GPS/GSM más pequeños, de menor consumo y a su vez con más poder de procesamiento. </p>
                        </div>
                        <div class="range-spacer veil reveal-lg-inline-block offset-lg-top-50"></div>
                        <div class="cell-lg-5">
                        <br/>
                          <p>Esto significó para el equipo la adquisición de conocimiento en áreas tales como enlaces de RF, sistemas empotrados, además del diseño de aplicaciones de software que permitieran el manejo remoto de redes de radiofrecuencia, enfocadas a soportar los servicios de rastreo y ubicación de vehículos hurtados. La integración significó además ser innovadores en materia de implementación y fabricación de los equipos de red a pequeña escala, de bajo consumo, fáciles de instalar, y sobre todo, de gran confiabilidad, por tratarse de un entorno geográfico tan exigente como el nuestro. </p>
                          <p>La destreza en el manejo de herramientas para el diseño de circuitos impresos, desarrollo de firmware, comunicaciones vía RF, redes celulares, bases de datos transaccionales, servicios web, etc. nos llevó a tomar el reto de diseñar y desarrollar nuestra propia plataforma tecnológica de hardware y la aplicación para el monitoreo remoto de vehículos y control de flotas vía Internet. Innovamos en el uso de herramientas de uso libre, sistemas operativos abiertos, así como bases de datos, de tal forma que pudimos implementar a bajo costo el sistema de monitoreo completo.</p>
                        </div>
                        <div class="cell-lg-5">
                            <br/>
                          <p>Hemos mejorado nuestras técnicas de desarrollo de firmware en un entorno con control de versiones, sistemas operativos de tiempo real y una documentación completa y precisa que permita el seguimiento y ampliación de las aplicaciones. </p>
                          <p>Incursionamos también en el desarrollo de aplicaciones web para la administración del negocio de nuestros clientes, con soluciones a la medida y en constante evolución. Soluciones que soportan los diferentes procesos transaccionales de las operadoras, como los procesos de agendamiento de citas, atención y seguimiento de solicitudes y administración financiera del negocio. Allí aplicamos y generamos conocimiento en ambientes Java, PHP motores y visores de mapas digitales, entre otros. El conocimiento adquirido nos llevó también a diseñar e implementar la segunda generación de los equipos y aplicaciones de la red de radiofrecuencia en el año 2007. Hoy día está implementado en más de diez países en el mundo. </p>
                        </div>
                        <div class="range-spacer veil reveal-lg-inline-block offset-lg-top-50"></div>
                        <div class="cell-lg-5">
                          <br/>
                        </div>
                        <div class="cell-lg-5">
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        )
    }
}
  export default LightParallaxBox;