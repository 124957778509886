import React from 'react';
import sitemap from '../default/sitemap.json';
import  Columm from './Colummsitemap';

class Rowmap extends React.Component {
        state = {
                sitemap: sitemap
              }

    render(){
        return (
        <main class="page-content">
                <section class="section-98 section-md-50">
                <div class="shell">
                <div class="range range-xs-center text-left">
                        <div className="cell-xs-7 cell-sm-4">
                                {this.state.sitemap.filter((e) => e.title === 'Inicio').map(
                                 e => 
                                    <div>
                                        <Columm e={e} ></Columm>
                                    </div>
                        
                                )}
                                {this.state.sitemap.filter((h) => h.title === 'Pages').map(
                                 h =>
                                    <div class="offset-top-41">
                                        <Columm e={h} ></Columm>                                                       
                                    </div>       
                                )}
                        </div>
                        <div class="cell-xs-7 cell-sm-4 offset-top-66 offset-sm-top-0">
                                {this.state.sitemap.filter((h) => h.title === 'Portafolio').map(
                                 h =>
                                    <div class="offset-top-41">
                                        <Columm e={h} ></Columm>                                                       
                                    </div>       
                                )}
                        </div>
                        <div class="cell-xs-7 cell-sm-4 offset-top-66 offset-sm-top-0">
                                {this.state.sitemap.filter((h) => h.title === 'Legal').map(
                                 h =>
                                    <div class="offset-top-41">
                                        <Columm e={h} ></Columm>                                                       
                                    </div>       
                                )}
                        </div>
                </div>


                </div>
                </section>
                
            </main>)
    }
}
  export default Rowmap;