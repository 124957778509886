import React from 'react';


class Whychooseus extends React.Component {
    render(){
        return (
            <div>
                <section class="section-top-50 section-sm-top-110 section-sm-bottom-110 section-lg-top-66 section-bottom-50 section-lg-bottom-0 bg-lightest">
                <div class="shell">
                    <div class="range range-sm-center range-md-middle">
                    <div class="cell-lg-5 veil reveal-lg-inline-block"><img class="img-responsive center-block" width="470" height="770" src="images/whychooseus.png" alt=""/></div>
                    <div class="cell-sm-10 cell-lg-5 section-lg-bottom-50">
                        <h1 class="offset-none">Nuestras Fortalezas</h1>
                        <hr class="divider bg-mantis"/>
                        <div class="offset-top-66 offset-lg-top-50">
                               
                                <div class="unit unit-sm unit-sm-horizontal text-sm-left">
                                    <div class="unit-left"><span class="icon text-gray mdi mdi-magnify"></span></div>
                                    <div class="unit-body">
                                    <h4 class="text-bold text-malibu offset-sm-top-14">Investigamos</h4>
                                    <p> Nuestra compañía ha adquirido conocimientos procedentes de distintas partes del mundo, a través de sedes de investigación y desarrollo en países como Colombia, España, Alemania, Brasil y China; lo que nos ha permitido tener un alcance y perspectiva única en nuestros proyectos.</p><br/>
                                    </div>
                                </div>
                        <div class="offset-top-66 offset-lg-top-34">
                                   
                                    <div class="unit unit-sm unit-sm-horizontal text-sm-left">
                                    <div class="unit-left"><span class="icon text-gray mdi mdi-settings"></span></div>
                                    <div class="unit-body">
                                        <h4 class="text-bold text-malibu offset-sm-top-14">Desarrollamos</h4>
                                        <p>Creamos soluciones desde una óptica científica e investigativa donde apoyados en desarrollos tecnológicos nuevos y propios construimos productos y servicios que se comercializan en Suramérica, Centroamérica, China y Europa. </p><br/>
                                    </div>
                                    </div>
                        </div>
                        <div class="offset-top-66 offset-lg-top-34">
                                   
                                    <div class="unit unit-sm unit-sm-horizontal text-sm-left">
                                    <div class="unit-left"><span class="icon text-gray mdi mdi-factory"></span></div>
                                    <div class="unit-body">
                                        <h4 class="text-bold text-malibu offset-sm-top-14">Producimos</h4>
                                        <p>Contamos con sedes de producción en Alemania y China que suplen las necesidades tecnológicas de la compañía y del mercado con eficiencia y prontitud. </p>
                                    </div>
                                    </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>)
            }
}
  export default Whychooseus;
