import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

class Footer extends React.Component {
    render(){
        return (
            <footer className="section-relative section-top-66 section-bottom-34 page-footer bg-gray-base context-dark">
            <div className="shell">
              <div className="range range-sm-center text-lg-left">
                <div className="cell-sm-8 cell-md-12">
                  <div className="range range-xs-center">
                    <div className="cell-xs-7 text-xs-left cell-md-4 cell-lg-3 cell-lg-push-4">
                      <h6 className="text-uppercase text-spacing-60">Contenido reciente</h6>
                            
                            <article className="post widget-post text-left text-picton-blue"><a href="blog-classic-single-post.html">
                                <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                                  <div className="unit-body">
                                  <div className="post-title">
                                  <a href="/legal"><h6 className="text-regular">Información legal</h6></a>
                                    </div>
                                    <div className="post-meta"><span className="icon-xxs mdi mdi-arrow-right"></span>
                                      <time className="text-dark" dateTime="2016-01-01">29/11/2019</time>
                                    </div>
                                  </div>
                                </div></a></article>
                                <article className="post widget-post text-left text-picton-blue"><a href="blog-classic-single-post.html">
                                <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                                  <div className="unit-body">
                                  <div className="post-title">
                                  <a href="/datos"><h6 className="text-regular">Autorización de Datos</h6></a>
                                    </div>
                                    <div className="post-meta"><span className="icon-xxs mdi mdi-arrow-right"></span>
                                      <time className="text-dark" dateTime="2016-01-01">29/11/2019</time>
                                    </div>
                                  </div>
                                </div></a></article>
                                                        
                    </div>
                    <div className="cell-xs-5 offset-top-41 offset-xs-top-0 text-xs-left cell-md-3 cell-lg-2 cell-lg-push-3">
                      <h6 className="text-uppercase text-spacing-60">Enlaces Útiles</h6>
                      <div className="reveal-block">
                        <div className="reveal-inline-block">
                          <ul className="list list-marked">
                            <li><a href="/about">Quienes somos</a></li>
                            <li><a href="/portafolio">Portafolio</a></li>
                            <li><a href="/proyectos">Proyectos</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="cell-xs-12 offset-top-41 cell-md-5 offset-md-top-0 text-md-left cell-lg-4 cell-lg-push-2">
                      <h6 className="text-uppercase text-spacing-60">IDEAS y DESARROLLOS TECNOLOGICOS </h6>

                            <p>Cra 7 No. 156-68 Edificio North Point Torre III Piso</p>
                            <p>piso 10 oficina 1006 / Bogotá - Colombia</p>
                            <p><i className="icon fa fa-phone icon-xxs icon-circle icon-darkest-filled" href="#"/>57 1 4929165 - 57 1 4043013 </p>
                            <p><i className="icon fa fa-envelope-square icon-xxs icon-circle icon-darkest-filled" href="#"/>info@tecnoideas.com</p>
                    
                          
                      <div className="offset-top-30">
                              
                      </div>
                    </div>
                    <div className="cell-xs-12 offset-top-66 cell-lg-3 cell-lg-push-1 offset-lg-top-0">  
                      <div className="footer-brand"><a href="index.html">{null}</a></div>
                      <p className="text-darker offset-top-4">Encuentranos en redes</p>
                            <ul className="list-inline">
                              <li><a target="_blank" className="icon fa fa-twitter icon-xxs icon-circle icon-darkest-filled" href="#">{null}</a></li>
                              <li><a target="_blank" className="icon fa fa-linkedin icon-xxs icon-circle icon-darkest-filled" href="#">{null}</a></li>
                            </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="shell offset-top-50">
              
              <p className="small text-darker"><a href="/sitemap"> Mapa del Sitio |</a><span id="copyright-year"></span> <a href="/legal">Información Legal |</a>
              </p>
            </div>
          </footer>
        )
    }
}
  export default Footer;