import React from 'react';

class Textimageblock extends React.Component {
    render(){
        return (
            <div>
                <section className="section-50">
                <div className="shell">
                    <div className="range range-xs-center">
                    <div className="cell-xs-10 cell-lg-6 cell-lg-push-2"><img className="img-responsive reveal-inline-block offset-top-10" src={this.props.image} width="960" height="540" alt=""/></div>
                    <div className="cell-xs-10 cell-lg-6 text-md-left inset-md-right-80 cell-lg-push-1 offset-top-50 offset-lg-top-0">
                        <h1>{this.props.title}</h1>
                        <hr className="divider hr-lg-left-0 bg-mantis"/>
                        <div className="offset-top-30 offset-md-top-50">
                            <p>{this.props.parrafo}</p>
       
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        )
    }
}
  export default Textimageblock;