import React from 'react';

class Intro extends React.Component{
    
    render() {
      return (

<section className="section-50">
          
          <div class="shell">
            <h1 style={{'text-align': 'center'}}>{this.props.title}</h1>
            <hr class="divider bg-mantis"/>
            <p class="inset-left-11p inset-right-11p">
            {this.props.parrafo}
            </p>
            <p class="inset-left-11p inset-right-11p">{this.props.parrafo2}</p>
            <p class="inset-left-11p inset-right-11p">{this.props.parrafo3}</p>
            </div>
</section>
)}}
export default Intro;