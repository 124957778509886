import React from 'react';
import Breadcrumb from '../components/Breadcrumbs';
import Textimageblock from '../components/Textimageblock';
import Intro from '../components/Intro';

class Portafolio extends React.Component {
    render(){
        return (
            <div>
               <Breadcrumb name="Portafolio" icon="mdi mdi-bulletin-board icon icon-white" />
               <Intro title="Portafolio de Productos" parrafo="Ideas y desarrollos tecnológicos diseña, desarrolla, produce, integra y comercializa soluciones tecnológicas de alta calidad. En el plano de los dispositivos electrónicos, nuestro portafolio de productos de hardware atienden las necesidades de nuestros clientes con un alto grado de personalización, suministrando soluciones llave en mano.
De la misma forma, nuestros aplicativos para la administración, gestión y monitoreo de bienes integran diferentes tecnologías disponibles en el mercado para brindar una amplia cobertura a través de múltiples opciones. Las funcionalidades con que cuentan las aplicaciones de software ofrecen diferentes módulos configurables enfocados a la seguridad, gestión, seguimiento y logística que permanecen en constante evolución. "/>
                <Textimageblock image ="images/p-hardware.jpg" title="Productos de Hardware" parrafo="Contamos con un amplio portafolio de hardware conformado por productos diseñados y fabricados directamente por nosotros, como también por soluciones de proveedores reconocidos en el mercado. Dentro de las opciones se cuenta con diferentes tipos de dispositivos de monitoreo satelital y sus accesorios, enfocados a múltiples sectores del mercado (Carga seca o líquida, hidrocarburos, personas, etc.)"/> 
              <Textimageblock image ="images/p-software.jpg" title="Productos de Software" parrafo="Contamos con un equipo de Ingenieros especializados en el desarrollo de aplicaciones de software orientadas a realizar una eficiente, ágil y práctica gestión de los procesos en los cuales operan. Gracias a técnicas de desarrollo de última generación y herramientas de programación de avanzada, nuestras aplicaciones se ajustan a las expectativas y necesidades de los clientes."/> 

            </div>
            
        )
    }
}
  export default Portafolio;