import React from 'react';
/*components import*/
import Accordion from '../components/ClassicAccordion';
import Breadcrumb from '../components/Breadcrumbs';
/*styles import*/
import '../css/style2.css';
import '../css/styles.css'
import legal from '../default/legal.json';

class AboutUs extends React.Component {
    state = {
        legal: legal
      }
    render(){
        return (
            <div>
                <Breadcrumb name="Información Legal" icon="icon-lg mdi mdi-file-outline icon icon-white"/>
                <main className="page-content">
                <div>
                <section className="section-50">
                <div class="shell">
                {this.state.legal.map(
                    e =>
                    
                <Accordion key ={e.id} title={e.title}>
                        {e.description}
                        {
                        (typeof(e.subdescriptions)=='object')?
                        <div>
                          {
                            e.subdescriptions.map(
                              f =>
                              <div>
                                <br/>
                            {typeof(f.index!=='undefined')?
                            <div>
                              {
                                <h4>{f.index}</h4>
                              }  
                            </div>
                            :null
                            }                                
                                {f.description}
                                <br/>
                                <br/>
                              </div>
                              
                            )
                          }
                        </div>
                        :<br/>
                        }   
                        
                    </Accordion>)}</div></section>
                    
                </div> 
                </main>
            </div>
            
        )
    }
}
  export default AboutUs;