import React from 'react';
import Breadcrumb from '../components/Breadcrumbs';
import Textimageblock from '../components/Textimageblock';
import Intro from '../components/Intro';
class Proyectos extends React.Component {
    render(){
        return (
            <div>
              <Breadcrumb name="Proyectos" icon="mdi mdi-presentation icon icon-white"/> 
              <Intro title="Portafolio de Servicios" parrafo="Ideas y desarrollos tecnológicos como centro de Investigación y Desarrollo se encuentra a la vanguardia en la prestación de servicios de tecnología para la creación de dispositivos electrónicos, desarrollo de aplicaciones de software, diseño, mantenimiento y soporte de plataformas de cómputo.

Hemos creado un amplio set de soluciones y servicios para agregar valor a sus clientes. Incorporando un número importante de tecnologías de punta. Ofrecemos servicios para la gestión de la información con soluciones punto a punto en diferentes sectores de la industria como: Sector asegurador, empresas de transporte y logística, en aplicaciones industriales, sector de hidrocarburos y en empresas de telecomunicaciones. "/>
              <Textimageblock image ="images/special-projects.jpg" title="Proyectos Especiales" parrafo="Nuestra experiencia en el mercado de desarrollo de soluciones tecnológicas, la presencia en diferentes partes del mundo a través de nuestros operadores y las excelentes relaciones con nuestros partners tecnológicos, posibilitan que seamos un excelente integrador de soluciones para la construcción e implementación de proyectos especiales que involucran diferentes aspectos tecnológicos (telemetría, control, gestión de procesos, automatización, aplicaciones y afines)."/> 
              <Textimageblock image ="images/foto1.jpg" title="Gestión y Administración de Plataformas" parrafo="Dentro de la cadena de valor del servicio de desarrollo de soluciones, ofrecemos la gestión, el soporte y el mantenimiento de sus aplicaciones de negocio o de operación, las cuales pueden ser alojadas en nuestras oficinas o en data centers de alta disponibilidad y redundancia, con estándares de operación elevados."/> 

            </div>
            
        )
    }
}
  export default Proyectos;