import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import menu from '../default/menu.json';
import logo from '../logo3_short.png';

class Menu extends React.Component{
  state = {
    menu: menu
  }
    render() {
      return (
        <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Navbar.Brand href="/">
      <img
        height="30"
        width="200"
        src={logo}
        className="d-inline-block align-top"
        alt="logo"
      />
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
    {this.state.menu.map(
                e => <Nav.Link key ={e.id} href={e.href} >{e.title}</Nav.Link>
              )
              }
    </Nav>
  </Navbar.Collapse>
</Navbar>
</div>
      );
    }  
  }

  export default Menu;
