import React, { useState, useRef, useEffect } from 'react'

const Accordion = props => {
  const [active, setActive] = useState(true)
  const contentRef = useRef(null)

  useEffect(() => {
    contentRef.current.style.maxHeight = active ? `${contentRef.current.scrollHeight}px` : '0px'
  }, [contentRef, active])

  const toogleActive = () => {
    setActive(!active)
  }

  const titleStyle = {
    fontWeight: 600,
    fontSize: '20px',
    color: 'white'
  }
  const accordionStyle = {
    "background-color": "#E1B850"
  }

  return (
    <div className="accordion-section" style={accordionStyle}>
      <button className="accordion-title" onClick={toogleActive} style={accordionStyle}>
        <p style={titleStyle}>{props.title}</p>
        <span className={active ? 'accordion-icon rotate': 'accordion-icon'} style={titleStyle}>
         >
        </span>
      </button>

      <div
        ref={contentRef}
        className="accordion-content"
      >
        {props.children}
      </div>
    </div>
  )
}

export default Accordion