import React from 'react';
import Breadcrumb from '../components/Breadcrumbs';
import Accordion from '../components/ClassicAccordion'
import Intro from '../components/Intro';
import privacidad from '../default/privacidad.json';



class Autorizacion extends React.Component {
    state = {
        legal: privacidad
      }

    render(){
        return (
            <div>
               <Breadcrumb name="Tratamiento de Datos" icon="icon-lg mdi mdi-file-outline icon icon-white"/>
               <Intro 
               title="Autorización para el Tratamiento de Datos Personales" 
               parrafo="Ideas y Desarrollos tecnológicos S.A.S, identificada con el NIT 900.026.595-8, domiciliada en Cra 7 No. 156-68 Edificio North Point Torre III piso 10 oficina 1006 de la ciudad de Bogotá D.C. - Colombia, responsable y/o encargada del tratamiento de datos personales, los cuales han sido recolectados con ocasión de la prestación de sus servicios, a través de distintos canales de comunicación y comercialización, en atención a lo dispuesto en la Ley 1581 de 2012 y el Decreto Reglamentario 1377 de 2013, específicamente lo contenido en el artículo 10 de dicho decreto, solicita la autorización de los titulares de los datos personales para continuar con el tratamiento de sus datos, teniendo en cuenta que el tratamiento podrá implicar la transferencia, transmisión y/o recepción de los datos, y el cual se realizará a través de sí misma, terceros encargados de tratamiento de información o de sus aliados comerciales, con finalidades como: mantener una eficiente comunicación de la información que sea de utilidad en los vínculos contractuales en los que sea parte el Titular de la Información, el cumplimiento de las obligaciones contraídas por el Responsable y/o Encargado del Tratamientos con los Titulares de los datos, la información de las modificaciones internas de cualquier tipo que se presenten en desarrollo de los vínculos contractuales con el Titular de los datos, la evaluación de la calidad de los servicios ofrecidos al Titular de los datos, la realización de estudios internos sobre los hábitos de los Titulares de los datos, el envío a través del medio seguro de la información a otros países en donde por temas de contingencias se tengan “back-ups” o respaldos de la información. "
               parrafo2="Los datos personales que obtengan el Responsable y/o Encargado del tratamiento de la información por parte del Titular de los datos o su representantes en el caso de personas jurídicas, serán compilados, almacenados, consultados, usados, compartidos, intercambiados, transmitidos, transferidos y objeto de tratamiento, en bases de datos que cuentan con las medidas de seguridad necesarias para la conservación adecuada de los datos. "
               parrafo3="Con la aceptación de la presente autorización, se permite el tratamiento de sus datos personales para las finalidades mencionadas y reconoce que los datos suministrados a las entidades Responsables o Encargadas del Tratamiento son ciertos, dejando por sentado que no se ha omitido o adulterado ninguna información. Ideas y Desarrollos tecnológicos S.A.S. respeta la confidencialidad y el derecho de habeas data de sus clientes y/o proveedores, razón por la cual le reiteramos que usted puede acceder, autorizar, conocer, modificar, actualizar, rectificar o suprimir la información suministrada, así como para revocar la autorización otorgada para el tratamiento de la misma a través del contacto telefónico 57 1 4929165 - 57 1 4043013 , en nuestro portal www.tecnoideas.com ó mediante el envío de una comunicación escrita a la dirección Cra 7 No. 156-68 Edificio North Point Torre III piso 10 oficina 1006 en la ciudad de Bogotá D.C., Colombia o al correo electrónico: info@tecnoideas.com"
               />
                <section className="section-50">
                <div class="shell">
                {this.state.legal.map(
                    e =>
                    
                <Accordion key ={e.id} title={e.title}>
                        {e.description}
                        {
                        (typeof(e.subdescriptions)=='object')?
                        <div>
                          {
                            e.subdescriptions.map(
                              f =>
                              <div>
                                <br/>
                            {typeof(f.index!=='undefined')?
                            <div>
                              {
                                <h4>{f.index}</h4>
                              }  
                            </div>
                            :null
                            }                                
                                {f.description}
                                <br/>
                                <br/>
                              </div>
                              
                            )
                          }
                        </div>
                        :<br/>
                        }   
                        
                    </Accordion>)}</div></section>
               
            </div>
            
        )
    }
}
  export default Autorizacion;